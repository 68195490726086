.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active{
  background: $primary-color;
  border-color: $primary-color;
}

#styleSelector {
  display: none;
}

#main-logo {
  height: 42px;
}

.sidenav-horizontal-next {
  display: none !important;
}

.ui-pnotify-sticker {
  display: none;
}

.ui-pnotify-title {
  margin-bottom: 0 !important;
}

#data-table-responsive_paginate {
  margin-top: 15px;
}

.dtsp-searchPane {
  flex-basis: 180px !important;
}

.dtsp-searchPane .dataTables_scrollBody {
  height: 90px !important;
}

.dtsp-searchPane .dtsp-countButton {
  display: none !important;
}

.filtersAccordion {
  border: solid 1px #ccc;
  border-radius: 6px;
  margin-top: -6px;
  position: relative;
  top: 6px;
}

.filtersAccordion .col, .filtersAccordion .col-auto {
  padding: 20px;
}

.filtersAccordion .col {
  padding-right: 0;
}

.filtersAccordion .col-auto {
  padding-left: 0;
}

.filtersAccordion h5 {
  font-size: .9375rem;
  margin-bottom: 0;
  padding-left: 10px;
}

#collapseSearchPane .dtsp-panesContainer {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  /*margin-bottom: -9px;*/
}

.filtersAccordion .dtsp-clearAll {
  border: 1px solid transparent;
  background-color: transparent;
  margin-top: -15px;
  margin-bottom: -15px;
  padding: 10px;
}

.filtersAccordion .dtsp-clearAll:hover {
  background-color: #f0f0f0;
  border-radius: 2px;
}

#data-table-responsive_filter {
  margin-top: 10px;
}

#collapseSearchPane .dtsp-title {
  display: none;
}

#collapseSearchPane .dataTables_scrollBody table + div {
  display: none !important;
}

.filtersAccordionHeader {
  cursor: pointer;
}

.filtersAccordionHeader h5, .filtersAccordionHeader .dtsp-titleRow, .filtersAccordionHeader .dtsp-title {
  display: inline;
}

.btn-card-plus {
  border-radius: 50% !important;
}

.btn-card-plus + button {
  margin-top: 5px;
}

.btn-edit-product, .btn-delete-product, .btn-edit-client, .btn-delete-client, .btn-edit-bill, .btn-delete-bill {
  margin-top: -13px;
  margin-bottom: -10px;
  border-radius: 50%;
}

.btn-edit-details {
  margin-left: 10px;
  margin-top: -18px;
  margin-bottom: -10px;
  border-radius: 50%;
}

span.dtr-data > .actionsHtml {
  margin-top: 10px;
}

.swal2-container {
  z-index: 1080 !important;
}

.swal2-input {
  margin-bottom: 0;
}

.label-bill-date {
  border-bottom: none;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  top: 3px;
}

.bill-datetime-picker input {
  margin-top: 0;
  padding-left: 19px;
}

#invalid-bill-date input {
  border-bottom: 1px solid #ff5252;
  padding-right: calc(1.5em + 1.25rem);
  background-image: $form-feedback-icon-invalid;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.3125rem) center;
  background-size: calc(0.75em + 0.625rem) calc(0.75em + 0.625rem);
}
